// Generated by Framer (4b8bcae)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./jdWPBWOGq-0.js";

const cycleOrder = ["nbSDqKolS", "Ejt00o4tB"];

const serializationHash = "framer-hVH93"

const variantClassNames = {Ejt00o4tB: "framer-v-146ztmc", nbSDqKolS: "framer-v-155a4u3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {IGeMnN5IH: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "nbSDqKolS", Open: "Ejt00o4tB"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "nbSDqKolS"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "nbSDqKolS", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-155a4u3", className, classNames)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"nbSDqKolS"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Ejt00o4tB: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1n25wlo"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"EM4_gkQ94"} style={{rotate: 0}} variants={{Ejt00o4tB: {rotate: -180}}}><SVG className={"framer-jzjcu9"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"f8FCJQbSc"} opacity={1} style={{backgroundColor: "rgb(255, 255, 255)"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-f699a178-53c9-4866-8c4e-caa245c86560, rgb(158, 158, 158)) /* {&quot;name&quot;:&quot;9E9E9E&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={2177663421} withExternalLayout {...addPropertyOverrides({Ejt00o4tB: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-5f9c34e1-c1a8-415b-a74b-c92716a82bf1, rgb(3, 169, 244))\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>", svgContentId: 2281508930}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hVH93.framer-2gdut9, .framer-hVH93 .framer-2gdut9 { display: block; }", ".framer-hVH93.framer-155a4u3 { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-hVH93 .framer-1n25wlo { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-hVH93 .framer-jzjcu9 { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Ejt00o4tB":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerjdWPBWOGq: React.ComponentType<Props> = withCSS(Component, css, "framer-hVH93") as typeof Component;
export default FramerjdWPBWOGq;

FramerjdWPBWOGq.displayName = "Caret";

FramerjdWPBWOGq.defaultProps = {height: 14, width: 14};

addPropertyControls(FramerjdWPBWOGq, {variant: {options: ["nbSDqKolS", "Ejt00o4tB"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerjdWPBWOGq, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})